import React, { useState, useRef, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import { useLocation } from '@reach/router'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import { FacebookShareButton, TwitterShareButton, EmailShareButton } from 'react-share'
import moment from 'moment'
import 'moment/locale/fr'
import 'moment/locale/en-gb'
import { useAtom } from 'jotai'
import { useIntl } from 'react-intl'
import Layout from 'components/Layout'
import Container from 'components/Container'
import Title from 'components/Title'
import Paragraph from 'components/Paragraph'
import Tag from 'components/Tag'
import BlogPostImage from 'components/BlogPostImage'
import innerHTML from '../utils/innerHTML'
import { metaTitle, metaDescription } from '../utils/page-meta-utils'
import { alternateLanguagesState } from '../atoms/languageSwitcher'

const BlogPostPage = ({ data }) => {
  if (!data) return null
  const intl = useIntl()
  const document = data.prismicBlogPost

  const [alternateLanguages, setAlternateLanguages] = useAtom(alternateLanguagesState)

  useEffect(() => {
    setAlternateLanguages(document.alternate_languages)
  }, [])

  const {
    data: {
      title: categoryTitle,
      tag_color_text: categoryTagColor,
      tag_color_background: categoryTagBackgroundColor,
      filter_color_light: categoryFilterColorLight,
      filter_color_dark: categoryFilterColorDark
    }
  } = document.data.category.document

  const publicationDate = moment(document.first_publication_date)
    .locale(intl.locale)
    .format('LL')

  const { href } = useLocation()
  const [shareSectionPosition, setShareSectionPosition] = useState(false)
  const articleSectionRef = useRef()
  const shareSectionRef = useRef()

  useScrollPosition(
    ({ currPos }) => {
      const articleEl = articleSectionRef.current
      const shareEl = shareSectionRef.current
      const headerEl = window.document.querySelector('.Header')

      if (Math.abs(currPos.y) < articleEl.offsetTop - headerEl.clientHeight) {
        setShareSectionPosition(false)
      } else if (
        Math.abs(currPos.y) >= articleEl.offsetTop - headerEl.clientHeight &&
        Math.abs(currPos.y) + shareEl.clientHeight < articleEl.offsetTop - headerEl.clientHeight + articleEl.clientHeight
      ) {
        setShareSectionPosition('top')
      } else {
        setShareSectionPosition('bottom')
      }
    }
  )

  return (
    <Layout pageName='BlogPostPage'>
      <Helmet>
        <title>{ document.data.meta_title || metaTitle(document.data.title.text, intl.formatMessage({ id: 'blog.title' })) }</title>
        <meta name='description' content={ document.data.meta_description || metaDescription(document.data.excerpt.text || document.data.content.text) } />
      </Helmet>
      <section className='BlogPostPage-header'>
        <Container size='small' className='BlogPostPage-headerContent'>
          <Tag style='rounded' label={ categoryTitle } className='BlogPostPage-tag' color={ categoryTagColor } backgroundColor={ categoryTagBackgroundColor } />
          <Title tag='h2' textAlign='center' className='BlogPostPage-title'>{ document.data.title.text }</Title>
          <Paragraph style='verySmall' textAlign='center'>{ intl.formatMessage({ id: 'blog.published_on' }, { date: publicationDate }) }</Paragraph>
          <div className='BlogPostPage-image'>
            <BlogPostImage
              filterColorLight={ categoryFilterColorLight }
              filterColorDark={ categoryFilterColorDark }
              src={ document.data.image.url }
            />
          </div>
        </Container>
      </section>
      <section className='BlogPostPage-article' ref={ articleSectionRef }>
        <div className='BlogPostPage-content Wysiwyg' { ...innerHTML(document.data.content.html) } />
        <div className={ `BlogPostPage-share ${shareSectionPosition ? `BlogPostPage-share--${shareSectionPosition}` : ''}` } ref={ shareSectionRef }>
          <FacebookShareButton url={ href }>
            <svg xmlns='http://www.w3.org/2000/svg' width='39' height='39' fill='none'>
              <circle cx='19.5' cy='19.5' r='19' fill='#fff' stroke='#002B68' />
              <path
                fill='#002B68'
                d='M31.909 19.5c0-6.853-5.556-12.41-12.41-12.41-6.852 0-12.408 5.557-12.408 12.41 0 6.194 4.538 11.327 10.47 12.258v-8.671h-3.15V19.5h3.15v-2.734c0-3.11 1.853-4.828 4.687-4.828 1.357 0 2.778.242 2.778.242v3.054H23.46c-1.541 0-2.022.957-2.022 1.94V19.5h3.441l-.55 3.587H21.44v8.671c5.932-.93 10.47-6.064 10.47-12.258z'
              />
              <path
                fill='#fff'
                d='M24.33 23.087l.55-3.587h-3.44v-2.327c0-.981.48-1.939 2.022-1.939h1.565v-3.053s-1.42-.243-2.778-.243c-2.835 0-4.687 1.718-4.687 4.828V19.5h-3.15v3.587h3.15v8.671c1.285.201 2.593.201 3.878 0v-8.671h2.891z'
              />
              <defs>
                <clipPath id='a'>
                  <path
                    fill='#fff'
                    d='M0 0h24.818v24.818H0z'
                    transform='translate(7.09 7.09)'
                  />
                </clipPath>
              </defs>
            </svg>
          </FacebookShareButton>
          <TwitterShareButton url={ href } title={ metaTitle(document.data.title.text) }>
            <svg xmlns='http://www.w3.org/2000/svg' width='39' height='39' fill='none'>
              <circle cx='19.5' cy='19.5' r='19' stroke='#002B68' />
              <path
                fill='#002B68'
                d='M16.144 28.734c8.027 0 12.418-6.651 12.418-12.419 0-.188 0-.376-.013-.564a8.881 8.881 0 002.178-2.259 8.701 8.701 0 01-2.507.687 4.38 4.38 0 001.919-2.414 8.744 8.744 0 01-2.772 1.06 4.369 4.369 0 00-7.437 3.98 12.392 12.392 0 01-8.995-4.56 4.367 4.367 0 001.35 5.826 4.326 4.326 0 01-1.98-.546v.055a4.366 4.366 0 003.502 4.278 4.348 4.348 0 01-1.971.075 4.37 4.37 0 004.077 3.031 8.758 8.758 0 01-6.459 1.81 12.357 12.357 0 006.69 1.956'
              />
              <defs>
                <clipPath id='a'>
                  <path
                    fill='#fff'
                    d='M0 0h21.273v21.273H0z'
                    transform='translate(9.454 9.455)'
                  />
                </clipPath>
              </defs>
            </svg>
          </TwitterShareButton>
          <EmailShareButton url={ href }>
            <svg xmlns='http://www.w3.org/2000/svg' width='39' height='39' fill='none'>
              <circle cx='19.5' cy='19.5' r='19' stroke='#002B68' />
              <path stroke='#002B68' d='M28.125 13.031L19.5 20.937l-8.625-7.906' />
              <path
                stroke='#002B68'
                d='M10.875 13.031h17.25V25.25a.719.719 0 01-.719.719H11.594a.719.719 0 01-.719-.719V13.031zm7.057 6.469l-6.835 6.266m16.807 0L21.068 19.5'
              />
            </svg>
          </EmailShareButton>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query BlogPostQuery($uid: String, $lang: String) {
    prismicBlogPost(uid: { eq: $uid }, lang: { eq: $lang }) {
      lang
      alternate_languages {
        id
        type
        lang
        uid
      }
      first_publication_date
      uid
      data {
        meta_title
        meta_description
        title {
          text
        }
        excerpt {
          text
        }
        content {
          text
          html
        }
        image {
          url
        }
        category {
          document {
            ...on PrismicBlogCategory {
              data {
                title
                tag_color_text
                tag_color_background
                filter_color_light
                filter_color_dark
              }
            }
          }
        }
      }
    }
  }
`

export default BlogPostPage
