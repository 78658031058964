import React from 'react'
import PropTypes from 'prop-types'
import { uid } from 'uid'
import hexToRGB from '../utils/hexToRGB'

const BlogPostImage = ({
  src = '',
  filterColorLight = '',
  filterColorDark = ''
}) => {
  const lightColor = hexToRGB(filterColorLight)
  const darkColor = hexToRGB(filterColorDark)
  const filterId = uid()

  return (
    <div className='BlogPostImage'>
      <svg className='BlogPostImage-filter' xmlns='http://www.w3.org/2000/svg'>
        <filter id={ filterId }>
          <feColorMatrix
            type='matrix' result='grayscale'
            values='1 0 0 0 0
              1 0 0 0 0
              1 0 0 0 0
              0 0 0 1 0'
          />
          <feComponentTransfer colorInterpolationFilters='sRGB' result='duotone'>
            <feFuncR type='table' tableValues={ `${darkColor.r / 255} ${lightColor.r / 255}` } />
            <feFuncG type='table' tableValues={ `${darkColor.g / 255} ${lightColor.g / 255}` } />
            <feFuncB type='table' tableValues={ `${darkColor.b / 255} ${lightColor.b / 255}` } />
            <feFuncA type='table' tableValues='0 1' />
          </feComponentTransfer>
        </filter>
      </svg>

      <img className='BlogPostImage-image' src={ src } style={ { filter: `url(#${filterId})` } } />
    </div>
  )
}

BlogPostImage.propTypes = {
  src: PropTypes.string,
  filterColorLight: PropTypes.string,
  filterColorDark: PropTypes.string
}

export default BlogPostImage
